exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Ubuntu+Mono:400,700);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Dosis);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Montserrat);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:400,400i,700);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Niconne);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Libre+Baskerville);", ""]);

// module
exports.push([module.id, "\n.symbol-table[data-v-329fe102]{color:#d4d4d4;display:flex;flex-direction:column;font-family:Ubuntu Mono,monospace;margin-bottom:1rem\n}\n.symbol-table__row[data-v-329fe102]{align-self:center;display:flex;flex-direction:row\n}\n.symbol-table__index[data-v-329fe102]{border:0;width:30px\n}\n.symbol-table__ellipsis[data-v-329fe102],.symbol-table__name[data-v-329fe102]{border:1px solid #d4d4d4;height:1.2rem;width:100px\n}\n.symbol-table__ellipsis[data-v-329fe102]{border-left:none\n}", "", {"version":3,"sources":["/home/sh4l/labodev/repositories/project-learning-compilers/src/components/structure-of-a-compiler/symbol-table.vue?vue&type=style&index=0&id=329fe102&scoped=true&lang=scss&"],"names":[],"mappings":";AAOA,+BAA+B,cAAc,aAAa,sBAAsB,kCAAkC,kBAAkB;CACnI;AACD,oCAAoC,kBAAkB,aAAa,kBAAkB;CACpF;AACD,sCAAsC,SAAS,UAAU;CACxD;AACD,8EAA8E,yBAAyB,cAAc,WAAW;CAC/H;AACD,yCAAyC,gBAAgB;CACxD","file":"symbol-table.vue?vue&type=style&index=0&id=329fe102&scoped=true&lang=scss&","sourcesContent":["\n@import url(\"https://fonts.googleapis.com/css?family=Ubuntu+Mono:400,700\");\n@import url(\"https://fonts.googleapis.com/css?family=Dosis\");\n@import url(\"https://fonts.googleapis.com/css?family=Montserrat\");\n@import url(\"https://fonts.googleapis.com/css?family=Roboto:400,400i,700\");\n@import url(\"https://fonts.googleapis.com/css?family=Niconne\");\n@import url(\"https://fonts.googleapis.com/css?family=Libre+Baskerville\");\n.symbol-table[data-v-329fe102]{color:#d4d4d4;display:flex;flex-direction:column;font-family:Ubuntu Mono,monospace;margin-bottom:1rem\n}\n.symbol-table__row[data-v-329fe102]{align-self:center;display:flex;flex-direction:row\n}\n.symbol-table__index[data-v-329fe102]{border:0;width:30px\n}\n.symbol-table__ellipsis[data-v-329fe102],.symbol-table__name[data-v-329fe102]{border:1px solid #d4d4d4;height:1.2rem;width:100px\n}\n.symbol-table__ellipsis[data-v-329fe102]{border-left:none\n}"],"sourceRoot":""}]);

// exports
