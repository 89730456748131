exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(true);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Ubuntu+Mono:400,700);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Dosis);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Montserrat);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:400,400i,700);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Niconne);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Libre+Baskerville);", ""]);

// module
exports.push([module.id, "\n.input-area[data-v-277f45c1]{align-self:center;border:0;display:flex;font-family:Ubuntu Mono,monospace;font-size:1em;margin:0;padding:0\n}\n.input-area__textarea[data-v-277f45c1]{background-color:#1e1e1e;border:1px solid #ced4d4;color:#d4d4d3;display:inline-block;font-size:1rem;height:10em;outline:none;padding:1em;width:20em\n}\n.input-area__textarea--error[data-v-277f45c1]{border-color:#94151b\n}", "", {"version":3,"sources":["/home/sh4l/labodev/repositories/project-learning-compilers/src/components/input-area.vue?vue&type=style&index=0&id=277f45c1&scoped=true&lang=scss&"],"names":[],"mappings":";AAOA,6BAA6B,kBAAkB,SAAS,aAAa,kCAAkC,cAAc,SAAS,SAAS;CACtI;AACD,uCAAuC,yBAAyB,yBAAyB,cAAc,qBAAqB,eAAe,YAAY,aAAa,YAAY,UAAU;CACzL;AACD,8CAA8C,oBAAoB;CACjE","file":"input-area.vue?vue&type=style&index=0&id=277f45c1&scoped=true&lang=scss&","sourcesContent":["\n@import url(\"https://fonts.googleapis.com/css?family=Ubuntu+Mono:400,700\");\n@import url(\"https://fonts.googleapis.com/css?family=Dosis\");\n@import url(\"https://fonts.googleapis.com/css?family=Montserrat\");\n@import url(\"https://fonts.googleapis.com/css?family=Roboto:400,400i,700\");\n@import url(\"https://fonts.googleapis.com/css?family=Niconne\");\n@import url(\"https://fonts.googleapis.com/css?family=Libre+Baskerville\");\n.input-area[data-v-277f45c1]{align-self:center;border:0;display:flex;font-family:Ubuntu Mono,monospace;font-size:1em;margin:0;padding:0\n}\n.input-area__textarea[data-v-277f45c1]{background-color:#1e1e1e;border:1px solid #ced4d4;color:#d4d4d3;display:inline-block;font-size:1rem;height:10em;outline:none;padding:1em;width:20em\n}\n.input-area__textarea--error[data-v-277f45c1]{border-color:#94151b\n}"],"sourceRoot":""}]);

// exports
