exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(true);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Ubuntu+Mono:400,700);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Dosis);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Montserrat);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:400,400i,700);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Niconne);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Libre+Baskerville);", ""]);

// module
exports.push([module.id, "\n.description[data-v-3af8f276]{display:flex;flex-direction:column;height:auto;min-width:500px\n}\n.description__paragraph[data-v-3af8f276]{align-self:flex-start\n}", "", {"version":3,"sources":["/home/sh4l/labodev/repositories/project-learning-compilers/src/components/description.vue?vue&type=style&index=0&id=3af8f276&scoped=true&lang=scss&"],"names":[],"mappings":";AAOA,8BAA8B,aAAa,sBAAsB,YAAY,eAAe;CAC3F;AACD,yCAAyC,qBAAqB;CAC7D","file":"description.vue?vue&type=style&index=0&id=3af8f276&scoped=true&lang=scss&","sourcesContent":["\n@import url(\"https://fonts.googleapis.com/css?family=Ubuntu+Mono:400,700\");\n@import url(\"https://fonts.googleapis.com/css?family=Dosis\");\n@import url(\"https://fonts.googleapis.com/css?family=Montserrat\");\n@import url(\"https://fonts.googleapis.com/css?family=Roboto:400,400i,700\");\n@import url(\"https://fonts.googleapis.com/css?family=Niconne\");\n@import url(\"https://fonts.googleapis.com/css?family=Libre+Baskerville\");\n.description[data-v-3af8f276]{display:flex;flex-direction:column;height:auto;min-width:500px\n}\n.description__paragraph[data-v-3af8f276]{align-self:flex-start\n}"],"sourceRoot":""}]);

// exports
