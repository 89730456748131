exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Ubuntu+Mono:400,700);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Dosis);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Montserrat);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:400,400i,700);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Niconne);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Libre+Baskerville);", ""]);

// module
exports.push([module.id, "\n.lexical-analysis[data-v-d379ab78]{display:flex;flex-direction:column\n}\n.lexical-analysis em[data-v-d379ab78]{color:#d4d4d4\n}", "", {"version":3,"sources":["/home/sh4l/labodev/repositories/project-learning-compilers/src/components/content/lexical-analysis.vue?vue&type=style&index=0&id=d379ab78&scoped=true&lang=scss&"],"names":[],"mappings":";AAOA,mCAAmC,aAAa,qBAAqB;CACpE;AACD,sCAAsC,aAAa;CAClD","file":"lexical-analysis.vue?vue&type=style&index=0&id=d379ab78&scoped=true&lang=scss&","sourcesContent":["\n@import url(\"https://fonts.googleapis.com/css?family=Ubuntu+Mono:400,700\");\n@import url(\"https://fonts.googleapis.com/css?family=Dosis\");\n@import url(\"https://fonts.googleapis.com/css?family=Montserrat\");\n@import url(\"https://fonts.googleapis.com/css?family=Roboto:400,400i,700\");\n@import url(\"https://fonts.googleapis.com/css?family=Niconne\");\n@import url(\"https://fonts.googleapis.com/css?family=Libre+Baskerville\");\n.lexical-analysis[data-v-d379ab78]{display:flex;flex-direction:column\n}\n.lexical-analysis em[data-v-d379ab78]{color:#d4d4d4\n}"],"sourceRoot":""}]);

// exports
