const namespaces = {
  array: '8ba9f94e-9580-11e8-921d-28cfe915048b',
  comma: '2299e222-9376-11e8-92ce-28cfe915048b',
  key: 'a292aa9c-9419-11e8-b616-28cfe915048b',
  pair: '5c9bb8c4-9038-11e8-b7d5-28cfe915048b',
  object: 'dd4391ac-9580-11e8-ae6a-28cfe915048b',
  value: '19ab8504-9118-11e8-a231-28cfe915048b',
};

export default namespaces;
