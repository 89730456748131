exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Ubuntu+Mono:400,700);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Dosis);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Montserrat);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:400,400i,700);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Niconne);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Libre+Baskerville);", ""]);

// module
exports.push([module.id, "\n.content--no-first-letter{margin-top:2rem;width:500px\n}\n.content--no-first-letter .input-area__textarea{padding:1rem;width:calc(100% - 2rem)\n}\n.content--no-first-letter .source-code{background-color:transparent;border:none;justify-self:flex-start;margin:0;padding:1rem;width:calc(100% - 2rem)\n}", "", {"version":3,"sources":["/home/sh4l/labodev/repositories/project-learning-compilers/src/components/content/a-simple-syntax-directed-translator/a-translator-for-simple-expressions.vue?vue&type=style&index=0&module=true&lang=css&"],"names":[],"mappings":";AAOA,0BAA0B,gBAAgB,WAAW;CACpD;AACD,gDAAgD,aAAa,uBAAuB;CACnF;AACD,uCAAuC,6BAA6B,YAAY,wBAAwB,SAAS,aAAa,uBAAuB;CACpJ","file":"a-translator-for-simple-expressions.vue?vue&type=style&index=0&module=true&lang=css&","sourcesContent":["\n@import url(\"https://fonts.googleapis.com/css?family=Ubuntu+Mono:400,700\");\n@import url(\"https://fonts.googleapis.com/css?family=Dosis\");\n@import url(\"https://fonts.googleapis.com/css?family=Montserrat\");\n@import url(\"https://fonts.googleapis.com/css?family=Roboto:400,400i,700\");\n@import url(\"https://fonts.googleapis.com/css?family=Niconne\");\n@import url(\"https://fonts.googleapis.com/css?family=Libre+Baskerville\");\n.content--no-first-letter{margin-top:2rem;width:500px\n}\n.content--no-first-letter .input-area__textarea{padding:1rem;width:calc(100% - 2rem)\n}\n.content--no-first-letter .source-code{background-color:transparent;border:none;justify-self:flex-start;margin:0;padding:1rem;width:calc(100% - 2rem)\n}"],"sourceRoot":""}]);

// exports
