import { render, staticRenderFns } from "./syntax-definition.vue?vue&type=template&id=420eb2c2&"
import script from "./syntax-definition.vue?vue&type=script&lang=js&"
export * from "./syntax-definition.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports