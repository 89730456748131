exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(true);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Ubuntu+Mono:400,700);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Dosis);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Montserrat);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:400,400i,700);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Niconne);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Libre+Baskerville);", ""]);

// module
exports.push([module.id, "\n.source-code[data-v-5a8e7960]{background-color:#1e1e1e;border:1px solid #d4d4d4;color:#d4d4d4;cursor:pointer;display:inline-flex;font-family:Ubuntu Mono,monospace;font-size:1rem;line-height:1.5rem;margin:.5rem;padding:.5rem;text-align:left;white-space:pre\n}\n.source-code__wrap[data-v-5a8e7960]{white-space:normal;width:250px;word-wrap:break-word\n}\n.source-code--color-clickable[data-v-5a8e7960]{border-color:#b4cea8;color:#b4cea8\n}", "", {"version":3,"sources":["/home/sh4l/labodev/repositories/project-learning-compilers/src/components/source-code.vue?vue&type=style&index=0&id=5a8e7960&scoped=true&lang=scss&"],"names":[],"mappings":";AAOA,8BAA8B,yBAAyB,yBAAyB,cAAc,eAAe,oBAAoB,kCAAkC,eAAe,mBAAmB,aAAa,cAAc,gBAAgB,eAAe;CAC9P;AACD,oCAAoC,mBAAmB,YAAY,oBAAoB;CACtF;AACD,+CAA+C,qBAAqB,aAAa;CAChF","file":"source-code.vue?vue&type=style&index=0&id=5a8e7960&scoped=true&lang=scss&","sourcesContent":["\n@import url(\"https://fonts.googleapis.com/css?family=Ubuntu+Mono:400,700\");\n@import url(\"https://fonts.googleapis.com/css?family=Dosis\");\n@import url(\"https://fonts.googleapis.com/css?family=Montserrat\");\n@import url(\"https://fonts.googleapis.com/css?family=Roboto:400,400i,700\");\n@import url(\"https://fonts.googleapis.com/css?family=Niconne\");\n@import url(\"https://fonts.googleapis.com/css?family=Libre+Baskerville\");\n.source-code[data-v-5a8e7960]{background-color:#1e1e1e;border:1px solid #d4d4d4;color:#d4d4d4;cursor:pointer;display:inline-flex;font-family:Ubuntu Mono,monospace;font-size:1rem;line-height:1.5rem;margin:.5rem;padding:.5rem;text-align:left;white-space:pre\n}\n.source-code__wrap[data-v-5a8e7960]{white-space:normal;width:250px;word-wrap:break-word\n}\n.source-code--color-clickable[data-v-5a8e7960]{border-color:#b4cea8;color:#b4cea8\n}"],"sourceRoot":""}]);

// exports
