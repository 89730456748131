<template>
  <div class='parse-trees'>
    <multimedia-content>A parse tree pictorially shows
how the start symbol of a grammar derives a string in the language.
If nonterminal <expr>A</expr> has a production <expr>A -> XYZ</expr>,
then a parse tree may have an interior node labeled <expr>A</expr>
with three children labeled <expr>X</expr>, <expr>Y</expr>,
and <expr>Z</expr>, from left to right :
        
Formally, given a context-free grammar, a <em>parse tree</em>
according to the grammar is a tree with the following properties:
<ol>
  <li>The root is labeled by the start symbol.</li>
  <li>Each leaf is labeled by a terminal or by <expr>epsilon</expr>.</li>
  <li>Each interior node is labeled by a nonterminal.</li>
  <li>If <em>A</em> is the nonterminal labeling some interior node and<br /> 
  <expr>X_1</expr>, <expr>X_2</expr>, ..., <expr>X_n</expr>
  are the labels of the children of that node from left to right,
  then there must be a production</li>
</ol>        
<formal-notation>
A -> <expr>X_1</expr>,<expr>X_2</expr>,...,<expr>X_n</expr>
</formal-notation>
Here, <expr>X_1</expr>, <expr>X_2</expr>, ..., <expr>X_n</expr>
each stand for a symbol that is either a terminal or a nonterminal.
As a special case, if <expr>A -> epsilon</expr> is a production,
then a node labeled <em>A</em> may have a single child labeled
<expr>epsilon</expr>.
<br />
From left to right, the leaves of a tree form the <em>yield</em> of
the tree, which is the string <em>generated</em> or <em>derived</em>
from the nonterminal at the root of the parse tree.
<br />
Another definition of the language generated by a grammar
is as the set of strings that can be generated by some parse tree.
The process of finding a parse tree for a given string of terminals
is called <em>parsing</em> that string.</multimedia-content>
  </div>
</template>

<script>
  import MultimediaContent from '../../multimedia-content.vue';
  import Expr from '../../expr/expr.vue';
  import FormalNotation from '../../formal-notation/formal-notation.vue';

  export default {
    name: 'syntax-definition',
    components: {
      MultimediaContent,
      Expr,
      FormalNotation,
    },
  }
</script>