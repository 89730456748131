exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Ubuntu+Mono:400,700);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Dosis);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Montserrat);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:400,400i,700);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Niconne);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Libre+Baskerville);", ""]);

// module
exports.push([module.id, "\n.introduction__container{display:flex;flex-direction:column;flex-wrap:wrap;margin-bottom:0;width:50%\n}\n.introduction__question-set{align-items:flex-start;display:flex;flex-direction:row;flex-wrap:wrap;margin:auto;width:500px\n}\n.introduction__subtitle{align-self:center;color:#ce9178;display:flex;font-family:Libre Baskerville,serif;font-size:1.2em;padding-left:1rem\n}", "", {"version":3,"sources":["/home/sh4l/labodev/repositories/project-learning-compilers/src/components/content/introduction.vue?vue&type=style&index=0&module=true&lang=scss&"],"names":[],"mappings":";AAOA,yBAAyB,aAAa,sBAAsB,eAAe,gBAAgB,SAAS;CACnG;AACD,4BAA4B,uBAAuB,aAAa,mBAAmB,eAAe,YAAY,WAAW;CACxH;AACD,wBAAwB,kBAAkB,cAAc,aAAa,oCAAoC,gBAAgB,iBAAiB;CACzI","file":"introduction.vue?vue&type=style&index=0&module=true&lang=scss&","sourcesContent":["\n@import url(\"https://fonts.googleapis.com/css?family=Ubuntu+Mono:400,700\");\n@import url(\"https://fonts.googleapis.com/css?family=Dosis\");\n@import url(\"https://fonts.googleapis.com/css?family=Montserrat\");\n@import url(\"https://fonts.googleapis.com/css?family=Roboto:400,400i,700\");\n@import url(\"https://fonts.googleapis.com/css?family=Niconne\");\n@import url(\"https://fonts.googleapis.com/css?family=Libre+Baskerville\");\n.introduction__container{display:flex;flex-direction:column;flex-wrap:wrap;margin-bottom:0;width:50%\n}\n.introduction__question-set{align-items:flex-start;display:flex;flex-direction:row;flex-wrap:wrap;margin:auto;width:500px\n}\n.introduction__subtitle{align-self:center;color:#ce9178;display:flex;font-family:Libre Baskerville,serif;font-size:1.2em;padding-left:1rem\n}"],"sourceRoot":""}]);

// exports
