exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Ubuntu+Mono:400,700);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Dosis);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Montserrat);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:400,400i,700);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Niconne);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Libre+Baskerville);", ""]);

// module
exports.push([module.id, "\n.expr[data-v-63f3dd2c]{color:#d4d4d4;display:inline-flex;font-family:Ubuntu Mono,monospace;font-size:1.1rem;line-height:1.5rem;margin:0;text-align:left;white-space:pre\n}", "", {"version":3,"sources":["/home/sh4l/labodev/repositories/project-learning-compilers/src/components/expr/expr.vue?vue&type=style&index=0&id=63f3dd2c&scoped=true&lang=scss&"],"names":[],"mappings":";AAOA,uBAAuB,cAAc,oBAAoB,kCAAkC,iBAAiB,mBAAmB,SAAS,gBAAgB,eAAe;CACtK","file":"expr.vue?vue&type=style&index=0&id=63f3dd2c&scoped=true&lang=scss&","sourcesContent":["\n@import url(\"https://fonts.googleapis.com/css?family=Ubuntu+Mono:400,700\");\n@import url(\"https://fonts.googleapis.com/css?family=Dosis\");\n@import url(\"https://fonts.googleapis.com/css?family=Montserrat\");\n@import url(\"https://fonts.googleapis.com/css?family=Roboto:400,400i,700\");\n@import url(\"https://fonts.googleapis.com/css?family=Niconne\");\n@import url(\"https://fonts.googleapis.com/css?family=Libre+Baskerville\");\n.expr[data-v-63f3dd2c]{color:#d4d4d4;display:inline-flex;font-family:Ubuntu Mono,monospace;font-size:1.1rem;line-height:1.5rem;margin:0;text-align:left;white-space:pre\n}"],"sourceRoot":""}]);

// exports
