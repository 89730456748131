exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Ubuntu+Mono:400,700);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Dosis);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Montserrat);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:400,400i,700);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Niconne);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Libre+Baskerville);", ""]);

// module
exports.push([module.id, "\n.code-sample[data-v-63875389]{display:flex;flex-direction:column;width:120px\n}\n.code-sample__line[data-v-63875389]{background-color:transparent;border:0;justify-content:flex-start;margin:0 auto;padding:0;width:100%\n}", "", {"version":3,"sources":["/home/sh4l/labodev/repositories/project-learning-compilers/src/components/structure-of-a-compiler/code-sample.vue?vue&type=style&index=0&id=63875389&scoped=true&lang=scss&"],"names":[],"mappings":";AAOA,8BAA8B,aAAa,sBAAsB,WAAW;CAC3E;AACD,oCAAoC,6BAA6B,SAAS,2BAA2B,cAAc,UAAU,UAAU;CACtI","file":"code-sample.vue?vue&type=style&index=0&id=63875389&scoped=true&lang=scss&","sourcesContent":["\n@import url(\"https://fonts.googleapis.com/css?family=Ubuntu+Mono:400,700\");\n@import url(\"https://fonts.googleapis.com/css?family=Dosis\");\n@import url(\"https://fonts.googleapis.com/css?family=Montserrat\");\n@import url(\"https://fonts.googleapis.com/css?family=Roboto:400,400i,700\");\n@import url(\"https://fonts.googleapis.com/css?family=Niconne\");\n@import url(\"https://fonts.googleapis.com/css?family=Libre+Baskerville\");\n.code-sample[data-v-63875389]{display:flex;flex-direction:column;width:120px\n}\n.code-sample__line[data-v-63875389]{background-color:transparent;border:0;justify-content:flex-start;margin:0 auto;padding:0;width:100%\n}"],"sourceRoot":""}]);

// exports
