<template>
  <div class='associativity-of-operators'>
    <multimedia-content>
By convention, <expr>9+5+2</expr> is equivalent to <expr>(9+5)+2</expr> and <expr>9-5-2</expr>
is equivalent to <expr>(9-5)-2</expr>.
When an operand like 5 has operators to its left and right,
conventions are needed for deciding which operator applies to that operand.
We say that the operator <expr>+</expr> <em>associates</em> to the left,
because an operand with plus signs on both sides of it belongs
to the operator to its left.
In most programming languages the four arithmetic operators,
addition, subtraction, multiplication, and division are left-associative.
<br />
Some common operators such as exponentiation are right-associative.
As another example, the assignment operator <expr>=</expr> in C
and its descendants is right-associative;
that is, the expression a=b=c is treated in the same way as the expression
<expr>a=(b=c)</expr>.
Strings like <expr>a=b=c</expr> with a right-associative operator are generated by the following grammar:

<formal-notation>
right   ->  letter = right | letter
letter  ->  a|b|...|z
</formal-notation>
Note that the parse tree for <expr>9-5-2</expr> grows down towards the left,
whereas the parse tree for <expr>a=b=c</expr> grows down towards the right.
</multimedia-content>
</div>
</template>

<script>
import MultimediaContent from '../../multimedia-content.vue';
import FormalNotation from '../../formal-notation/formal-notation.vue';
import Expr from '../../expr/expr.vue';
import SourceCode from '../../source-code.vue';

export default {
  name: 'associativity-of-operators',
  components: {
    MultimediaContent,
    FormalNotation,
    SourceCode,
    Expr
  },
}
</script>

<style module>
  @import './a-simple-syntax-directed-translator.scss';
</style>