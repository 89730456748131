exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Ubuntu+Mono:400,700);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Dosis);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Montserrat);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:400,400i,700);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Niconne);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Libre+Baskerville);", ""]);

// module
exports.push([module.id, "\n.semantic-analysis[data-v-e1b48bca]{display:flex\n}\n.semantic-analysis em[data-v-e1b48bca]{color:#d4d4d4\n}\n.semantic-analysis strong[data-v-e1b48bca]{color:#d4d4d4;font-weight:700\n}", "", {"version":3,"sources":["/home/sh4l/labodev/repositories/project-learning-compilers/src/components/content/semantic-analysis.vue?vue&type=style&index=0&id=e1b48bca&scoped=true&lang=scss&"],"names":[],"mappings":";AAOA,oCAAoC,YAAY;CAC/C;AACD,uCAAuC,aAAa;CACnD;AACD,2CAA2C,cAAc,eAAe;CACvE","file":"semantic-analysis.vue?vue&type=style&index=0&id=e1b48bca&scoped=true&lang=scss&","sourcesContent":["\n@import url(\"https://fonts.googleapis.com/css?family=Ubuntu+Mono:400,700\");\n@import url(\"https://fonts.googleapis.com/css?family=Dosis\");\n@import url(\"https://fonts.googleapis.com/css?family=Montserrat\");\n@import url(\"https://fonts.googleapis.com/css?family=Roboto:400,400i,700\");\n@import url(\"https://fonts.googleapis.com/css?family=Niconne\");\n@import url(\"https://fonts.googleapis.com/css?family=Libre+Baskerville\");\n.semantic-analysis[data-v-e1b48bca]{display:flex\n}\n.semantic-analysis em[data-v-e1b48bca]{color:#d4d4d4\n}\n.semantic-analysis strong[data-v-e1b48bca]{color:#d4d4d4;font-weight:700\n}"],"sourceRoot":""}]);

// exports
