exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(true);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Ubuntu+Mono:400,700);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Dosis);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Montserrat);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:400,400i,700);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Niconne);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Libre+Baskerville);", ""]);

// module
exports.push([module.id, "\n.link[data-v-13b875c3]{display:inline;font-size:1rem;line-height:1rem\n}\n.link[data-v-13b875c3]:active,.link[data-v-13b875c3]:hover,.link[data-v-13b875c3]:link,.link[data-v-13b875c3]:visited{color:#b4cea8;font-size:1rem;line-height:1rem;text-decoration:underline\n}", "", {"version":3,"sources":["/home/sh4l/labodev/repositories/project-learning-compilers/src/components/browsable-link.vue?vue&type=style&index=0&id=13b875c3&scoped=true&lang=scss&"],"names":[],"mappings":";AAOA,uBAAuB,eAAe,eAAe,gBAAgB;CACpE;AACD,sHAAsH,cAAc,eAAe,iBAAiB,yBAAyB;CAC5L","file":"browsable-link.vue?vue&type=style&index=0&id=13b875c3&scoped=true&lang=scss&","sourcesContent":["\n@import url(\"https://fonts.googleapis.com/css?family=Ubuntu+Mono:400,700\");\n@import url(\"https://fonts.googleapis.com/css?family=Dosis\");\n@import url(\"https://fonts.googleapis.com/css?family=Montserrat\");\n@import url(\"https://fonts.googleapis.com/css?family=Roboto:400,400i,700\");\n@import url(\"https://fonts.googleapis.com/css?family=Niconne\");\n@import url(\"https://fonts.googleapis.com/css?family=Libre+Baskerville\");\n.link[data-v-13b875c3]{display:inline;font-size:1rem;line-height:1rem\n}\n.link[data-v-13b875c3]:active,.link[data-v-13b875c3]:hover,.link[data-v-13b875c3]:link,.link[data-v-13b875c3]:visited{color:#b4cea8;font-size:1rem;line-height:1rem;text-decoration:underline\n}"],"sourceRoot":""}]);

// exports
