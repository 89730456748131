exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(true);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Ubuntu+Mono:400,700);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Dosis);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Montserrat);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:400,400i,700);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Niconne);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Libre+Baskerville);", ""]);

// module
exports.push([module.id, "\n.paragraph[data-v-dcef856c]{align-self:center;color:#ce9178;display:inline-block;font-family:Roboto,sans-serif;font-size:1rem;font-weight:100;line-height:1.5rem;margin-top:0;padding:1rem;text-align:left;white-space:pre\n}\n.paragraph--align-right[data-v-dcef856c]{text-align:right\n}\n.paragraph[data-v-dcef856c]:first-letter{font-family:Libre Baskerville,serif;font-size:1.2rem\n}", "", {"version":3,"sources":["/home/sh4l/labodev/repositories/project-learning-compilers/src/components/paragraph.vue?vue&type=style&index=0&id=dcef856c&scoped=true&lang=scss&"],"names":[],"mappings":";AAOA,4BAA4B,kBAAkB,cAAc,qBAAqB,8BAA8B,eAAe,gBAAgB,mBAAmB,aAAa,aAAa,gBAAgB,eAAe;CACzN;AACD,yCAAyC,gBAAgB;CACxD;AACD,yCAAyC,oCAAoC,gBAAgB;CAC5F","file":"paragraph.vue?vue&type=style&index=0&id=dcef856c&scoped=true&lang=scss&","sourcesContent":["\n@import url(\"https://fonts.googleapis.com/css?family=Ubuntu+Mono:400,700\");\n@import url(\"https://fonts.googleapis.com/css?family=Dosis\");\n@import url(\"https://fonts.googleapis.com/css?family=Montserrat\");\n@import url(\"https://fonts.googleapis.com/css?family=Roboto:400,400i,700\");\n@import url(\"https://fonts.googleapis.com/css?family=Niconne\");\n@import url(\"https://fonts.googleapis.com/css?family=Libre+Baskerville\");\n.paragraph[data-v-dcef856c]{align-self:center;color:#ce9178;display:inline-block;font-family:Roboto,sans-serif;font-size:1rem;font-weight:100;line-height:1.5rem;margin-top:0;padding:1rem;text-align:left;white-space:pre\n}\n.paragraph--align-right[data-v-dcef856c]{text-align:right\n}\n.paragraph[data-v-dcef856c]:first-letter{font-family:Libre Baskerville,serif;font-size:1.2rem\n}"],"sourceRoot":""}]);

// exports
