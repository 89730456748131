exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(true);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Ubuntu+Mono:400,700);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Dosis);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Montserrat);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:400,400i,700);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Niconne);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Libre+Baskerville);", ""]);

// module
exports.push([module.id, "\n.question[data-v-747b4307]{cursor:pointer;display:flex;flex-direction:column;width:100%\n}\n.question__question[data-v-747b4307]{align-self:flex-start;margin-bottom:0;padding-top:0;width:calc(50% - 2rem)\n}\n.question__question[data-v-747b4307]:first-child{margin-top:1rem\n}\n.question__answer[data-v-747b4307]{align-self:flex-start;color:#d4d4d4;padding-bottom:0;padding-top:0;width:400px\n}\n.question__answer[data-v-747b4307]:first-letter{font-size:1rem\n}", "", {"version":3,"sources":["/home/sh4l/labodev/repositories/project-learning-compilers/src/components/question.vue?vue&type=style&index=0&id=747b4307&scoped=true&lang=scss&"],"names":[],"mappings":";AAOA,2BAA2B,eAAe,aAAa,sBAAsB,UAAU;CACtF;AACD,qCAAqC,sBAAsB,gBAAgB,cAAc,sBAAsB;CAC9G;AACD,iDAAiD,eAAe;CAC/D;AACD,mCAAmC,sBAAsB,cAAc,iBAAiB,cAAc,WAAW;CAChH;AACD,gDAAgD,cAAc;CAC7D","file":"question.vue?vue&type=style&index=0&id=747b4307&scoped=true&lang=scss&","sourcesContent":["\n@import url(\"https://fonts.googleapis.com/css?family=Ubuntu+Mono:400,700\");\n@import url(\"https://fonts.googleapis.com/css?family=Dosis\");\n@import url(\"https://fonts.googleapis.com/css?family=Montserrat\");\n@import url(\"https://fonts.googleapis.com/css?family=Roboto:400,400i,700\");\n@import url(\"https://fonts.googleapis.com/css?family=Niconne\");\n@import url(\"https://fonts.googleapis.com/css?family=Libre+Baskerville\");\n.question[data-v-747b4307]{cursor:pointer;display:flex;flex-direction:column;width:100%\n}\n.question__question[data-v-747b4307]{align-self:flex-start;margin-bottom:0;padding-top:0;width:calc(50% - 2rem)\n}\n.question__question[data-v-747b4307]:first-child{margin-top:1rem\n}\n.question__answer[data-v-747b4307]{align-self:flex-start;color:#d4d4d4;padding-bottom:0;padding-top:0;width:400px\n}\n.question__answer[data-v-747b4307]:first-letter{font-size:1rem\n}"],"sourceRoot":""}]);

// exports
