import AModelOfACompilerFrontEnd from './a-model-of-a-compiler-front-end.vue';
import SyntaxDefinition from './syntax-definition.vue';
import DefinitionOfGrammars from './definition-of-grammars.vue';
import ParseTrees from './parse-trees.vue';
import Ambiguity from './ambiguity.vue';
import TokensVersusTerminals from './tokens-versus-terminals.vue';
import AssociativityOfOperators from './associativity-of-operators.vue';
import PrecedenceOfOperators from './precedence-of-operators.vue';
import ATranslatorForSimpleExpressions from './a-translator-for-simple-expressions.vue';
import LexicalAnalyzer from './lexical-analyzer.vue';

export default {
  AModelOfACompilerFrontEnd,
  SyntaxDefinition,
  DefinitionOfGrammars,
  ParseTrees,
  Ambiguity,
  TokensVersusTerminals,
  AssociativityOfOperators,
  PrecedenceOfOperators,
  ATranslatorForSimpleExpressions,
  LexicalAnalyzer,
};
