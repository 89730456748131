import About from './about.vue';

import Introduction from './introduction.vue';

import StructureOfACompiler from './structure-of-a-compiler.vue';
import GroupingOfPhasesIntoPasses from './grouping-of-phases-into-passes.vue';
import CompilerConstructionTools from './compiler-construction-tools.vue';
import TheScienceOfBuildingACompiler from './the-science-of-building-a-compiler.vue';

import TheScienceOfCodeOptimization from './the-science-of-code-optimization.vue';
import TheEvolutionOfProgrammingLanguages from './the-evolution-of-programming-languages.vue';

import TheMoveToHigherLevelLanguages from './the-move-to-higher-level-languages.vue';
import ImpactsOnCompilers from './impacts-on-compilers.vue';

import ApplicationsOfCompilerTechnology from './applications-of-compiler-technology.vue';
import ImplementationOfHighLevelProgrammingLanguages from './implementation-of-high-level-programming-languages.vue';
import OptimizationsForComputerArchitectures from './optimizations-for-computer-architectures.vue';
import ProgramTranslations from './program-translations.vue';
import SoftwareProductivityTools from './software-productivity-tools.vue';

import ASimpleSyntaxDirectedTranslator from './a-simple-syntax-directed-translator';
import ProgrammingLanguageBasics from './programming-language-basics';
import LexicalAnalysis from './3-lexical-analysis';

const ContentItems = {
  About,
  Introduction,
  StructureOfACompiler,
  GroupingOfPhasesIntoPasses,
  CompilerConstructionTools,
  TheScienceOfBuildingACompiler,
  TheScienceOfCodeOptimization,
  TheEvolutionOfProgrammingLanguages,
  TheMoveToHigherLevelLanguages,
  ImpactsOnCompilers,
  ApplicationsOfCompilerTechnology,
  ImplementationOfHighLevelProgrammingLanguages,
  OptimizationsForComputerArchitectures,
  ProgramTranslations,
  SoftwareProductivityTools,
};

const Content = {
  ...ContentItems,
  ...ASimpleSyntaxDirectedTranslator,
  ...ProgrammingLanguageBasics,
  ...LexicalAnalysis,
};

export default Content;
