<template>
  <div class='content'>
    <multimedia-content> Compiler design is not only about compilers, and
many people use the technology learned by studying compilers in school, yet have never, strictly speaking, written (even part of) a compiler for a major programming language. Compiler technology has other important uses as well. Additionally, compiler design impacts several other areas of computer science. In this section, we review the most important interactions and applications of the technology.</multimedia-content>
  </div>
</template>

<script>
import MultimediaContent from '../multimedia-content.vue';

export default {
  name: 'applications-of-compiler-technology',
  components: {
    'multimedia-content': MultimediaContent
  },
}
</script>
